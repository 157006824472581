<!-- 预览标签页面 -->
<template>
  <div class="topic-details">
    <!-- 题 -->
    <el-card class="active" ref="barparent">
      <el-card class="box-card-left-a">
        <span class="caseSN_span">当前问题：{{ caseSN }}</span>
        <span class="caseSN_span"
          >标签模板:{{ labelManagement.templateName }}</span
        >

        <el-scrollbar style="height: 40%">
          <div
            class="Tagquestions"
            v-html="Tagquestions"
            style="height: 85%; width: 659px"
          ></div>
        </el-scrollbar>
        <el-divider></el-divider>
        <el-scrollbar style="height: 30%">
          <div class="analysis" style="display: flex">
            本题解析:
            <i v-html="labelManagement.SubjectAnalytical"></i>
          </div>
        </el-scrollbar>
        <span class="caseSN_span caseSN_keyword"
          >关键字：
          <template v-if="keyword.length > 0">
            <el-tag
              class="caseSN_tag"
              v-for="(i, index) in keyword"
              :key="index"
              >{{ i }}</el-tag
            ></template
          >
          <template v-if="keyword.length <= 0"> 暂无 </template>
        </span>
      </el-card>
      <el-card class="box-card-right-b">
        <el-scrollbar style="height: 100%">
          <TagQuestions
            :num="resourceId"
            :ProfessorOrNot="ProfessorOrNot"
            :completionStatus="completionStatus"
            :openId="openId"
            @change-label-data="changeLableData"
            @chang-Expert-Analysis="changExpertAnalysis"
            :data="tagTemplateData"
          />
        </el-scrollbar>
      </el-card>
    </el-card>
    <div
      :class="
        this.$store.state.toggle_collapsed
          ? 'activity-button wth'
          : 'activity-button w100'
      "
    >
      <el-button type="primary" style="padding-right: 14px" @click="nextSubject"
        >下一题</el-button
      >
      <el-button @click="edit">退出</el-button>
    </div>
  </div>
</template>

<script>
import { resourceDetail, templateDetail } from "../../api/api";
import TagQuestions from "../TagQuestions/index.vue";
export default {
  props: ["yuLanObj"],
  data() {
    return {
      content: [],
      params: {},
      requestTagTemplateData: [],
      tagTemplateData: [],
      active: 0,
      taskAmount: null,
      subjectId: 0,
      Tagquestions: null,
      labelManagement: { SubjectAnalytical: null, templateName: null },
      caseSN: null, //
      openId: [],
      resourceId: null,
      ProfessorOrNot: "提交标准答案",
      completionStatus: "",
      keyword: [],
    };
  },
  components: {
    TagQuestions,
  },
  created() {
    this.getData({ id: this.yuLanObj.caseIds[this.subjectId] });
  },
  methods: {
    changeLableData() {},
    changExpertAnalysis() {},
    edit() {
      this.$emit("editOut", "");
    },
    getData(params) {
      //初始化
      resourceDetail(params).then((res) => {
        if (res.data.keyword == "NULL") {
          this.keyword = [];
        } else {
          this.keyword = res.data.keyword.split(",");
        }
        this.caseSN = res.data.name;
        this.labelManagement.SubjectAnalytical = res.data.analysis;
        this.Tagquestions = res.data.topic;
      });
      //获取标签
      templateDetail({ id: this.yuLanObj.labelId }).then((res) => {
        this.requestTagTemplateData = res.data.label;
        this.labelManagement.templateName = res.data.name;
        this.Processinglabeldata(this.requestTagTemplateData); //zzzz处理这组数据
      });
    },
    //处理标签数据
    Processinglabeldata(val) {
      if (val.length > 0) {
        val.map((item) => {
          this.openId.push(item.id);
          // 1数值型，2多选型，2单选型，4文本型  typeof判断数据类型
          if (item.tag.length > 0) {
            item.tag.map((tag) => {
              tag["onAnalysis"] = {
                show: 0,
                con: 0,
              };
              tag.analysis = "";
              if (tag.type == 3) {
                //多选
                var arr = [];
                tag.answer = [];
                tag.numberLowerLimit = null;
                tag.numberSupperLimit = null;
                if (tag.content.length > 0 && tag.content != null) {
                  if (!(tag.content instanceof Array)) {
                    //判断是不是数组，不是数组处理成数组
                    var e = tag.content.split(",");
                    e.map((item, index) => {
                      arr.push({
                        id: index + 1,
                        name: item,
                      });
                    });
                    tag.content = arr;
                  }
                }
              } else if (tag.type == 2) {
                //单选
                var arr = [];
                tag.answer = "";
                tag.numberLowerLimit = null;
                tag.numberSupperLimit = null;
                if (tag.content.length > 0) {
                  if (!(tag.content instanceof Array)) {
                    var e = tag.content.split(",");
                    e.map((item, index) => {
                      arr.push({
                        id: index + 1,
                        name: item,
                      });
                    });
                    tag.content = arr;
                  }
                }
              } else if (tag.type == 4) {
                tag.numberLowerLimit = null;
                tag.numberSupperLimit = null;
                tag.answer = "";
              } else if (tag.type == 1) {
                if (!(tag.content instanceof Array)) {
                  var e = tag.content.split("~");
                  tag.answer = Number(e[0]);
                  tag.numberLowerLimit = Number(e[0]);
                  tag.numberSupperLimit = Number(e[1]);
                  tag.content = e;
                }
              }
            });
          }

          if (item.children.length > 0) {
            this.Processinglabeldata(item.children);
          }
        });
      }
      this.tagTemplateData = this.requestTagTemplateData;
    },

    // 下一题
    nextSubject() {
      this.subjectId = this.subjectId + 1;
      let active = this.yuLanObj.caseIds.length;
      if (this.subjectId < active) {
        this.getData({ id: this.yuLanObj.caseIds[this.subjectId] });
      } else {
        this.$message.error("已经是最后一题了!");
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../style/subject/subject.less";
@import "../../style/mouse.less";
.class3 {
  background: #d8e8ff;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.class4 {
  background: green;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}

.stemContent/deep/img {
  vertical-align: middle;
}
.choices/deep/p {
  display: inline-block;
  & img {
    height: 100%;
    vertical-align: middle;
  }
}
.activity-button {
  width: 100% !important;
}
.Tagquestions {
  /deep/iframe {
    height: 100%;
    width: 100%;
  }
}
/deep/.caseSN_tag {
  background-color: #ecf5ff !important;
  border-color: #d9ecff !important;
  display: inline-block !important;
  height: 32px !important;
  padding: 0 10px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  color: #409eff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  margin-right: 10px !important;
}
.caseSN_keyword {
  height: 32px !important;
  line-height: 32px !important;
}
</style>
