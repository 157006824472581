<!-- 题目 -->
<template>
  <div class="answerPage">
    <!-- 题 -->
    <el-card class="active" ref="barparent">
      <el-card class="answer">
        <div>
          <span>
            问题集:&nbsp;{{questions.title}}
            <i v-if="false">[ {{questionsType[questions.type]}} ]</i>
          </span>
          <p>{{questions.des}}</p>
        </div>
      </el-card>
      <el-card class="box-card-left">
        <el-scrollbar style="height:90%">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            @node-click="handleNodeClick"
            :highlight-current="true"
            class="tree"
          ></el-tree>
        </el-scrollbar>
      </el-card>
      <el-card class="box-card-right">
        <el-scrollbar style="height:100%">
          <div v-if="answers.length>0">
            <div
              v-for="(item,i) in answers"
              :key="i"
              class="text-option"
              :class="status == 0&&item.isCorrect == 1? 'text-option green':isComplete == 1? item.userChoce==1 ? 'text-option class3':'text-option':chooseIdObj[item.id]==1 ? 'text-option class3':'text-option'"
              v-html="item.solution"
            ></div>
          </div>

          <div style="padding-top: 20px;" v-html="detailContent" v-else></div>
        </el-scrollbar>
      </el-card>
    </el-card>
    <div :class="this.$store.state.toggle_collapsed?'activity-button wth':'activity-button w100'">
      <el-button :disabled="showButton" type="primary" style="padding-right: 14px;">提交</el-button>
      <el-button @click="edit">退出</el-button>
    </div>
  </div>
</template>

<script>
import screen from '@/components/screen'
import timer from '@/components/timer_s'
import { activeDetails } from '../../api/api'
export default {
  props: ['yuLanObj'],
  data () {
    return {
      active: 3,
      taskAmount: 3,
      count: 0,
      time: '00:00',
      questions: {
        title: '',
        type: 1,
        des: ''
      },
      questionsType: {
        1: '单选题',
        2: '多选题'
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'nodeName'
      },
      totalTime: 0,
      showButton: true,
      expandInterval: null,
      currentId: null,
      expandTimeArr: [],
      current: null,
      nodeid: '',
      optionSN: null,
      detailContent: '',
      answers: [],
      chooseIdObj: {},
      isComplete: 0,
      paramsObj: {},
      startTime: '',
      status: 0,//0  老师  1  学生
    }
  },

  components: {
    screen,
    timer
  },
  created () {

  },
  mounted () {
    this.startTime = new Date().getTime();
    let params = {
      "activeId": null,
      "caseId": this.yuLanObj.caseIds,
      "caseType": 1,
      "isComplete": 0,
      "taskId": null
    }
    this.getImmxCase(params)
    this.analysis()
  },
  watch: {
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity('新建K12活动')) {
        this.status = 0
      }
    },
    handleNodeClick (e) { //点击节点    
      if (e.optionSN > 0) {  //排除根节点
        let param = {
          nodeId: e.nodeid,
          activeId: null,
          caseId: this.yuLanObj.caseIds, //变式id
          isComplete: 0, // 1答案，0做题
          taskId: null
        }
        //请求右侧----------
        this.$api.page.getIMMXDetail(param).then((res) => {
          this.answers = [];
          this.detailContent = '';
          if (res.data.data) {
            if (res.data.data.detail) {   //右侧描述
              this.detailContent = res.data.data.detail.detail;
            } else if (res.data.data.answers) {  //右侧选项
              this.answers = res.data.data.answers
            }
          }
        })
        if (this.current) {  //排除第一个点击节点
          this.expandTimeArr.push({
            nodeId: this.nodeid,
            spendTime: this.current,
            optionSN: this.optionSN,
            recordTime: new Date().getTime()
          })
        }
        this.nodeid = e.nodeid
        this.optionSN = e.optionSN
        this.current = null
        clearInterval(this.expandInterval)
        this.expandInterval = setInterval(() => {
          this.currentId = e.nodeid;
          this.current++;
        }, 1000)
      }
    },
    getImmxCase (params) { //get数据

      this.$api.page.getImmxCase(params).then((res) => {
        if (res.data.data) {
          let arr = res.data.data;
          this.treeData = arr.nodes;
          this.totalTime = arr.totalSeconds || 0  //获取上次做题时长
          this.questions = {
            title: arr.psname,
            des: '',
            type: 0
          }
        }
      })
    },
    // 退出
    edit () {
      this.$emit('editOut', '')
    },



  }
}

</script>
<style lang='less' scoped>
@import '../../style/answerPage/answerPage.less';
@import '../../style/mouse.less';
.class3 {
  background: #d8e8ff;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}
.stemContent/deep/img {
  vertical-align: middle;
}
.choices/deep/p {
  display: inline-block;
  & img {
    height: 100%;
    vertical-align: middle;
  }
}
.tree/deep/.el-tree-node__expand-icon.is-leaf {
  width: 12px;
  height: 12px;
  font-size: 16px;
  background-size: 16px;
  margin-right: 5px;
  background: #615a5a;
  border-radius: 50%;
}
.tree/deep/.el-tree-node.is-current > .el-tree-node__content {
  background: #d8e8ff;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.el-scrollbar/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.answerPage/deep/table {
  margin-top: 10px;
}
.answerPage/deep/table td {
  border: 1px solid #615a5a;
  vertical-align: middle;
  text-align: center;
  height: 70px;
  padding: 12px 0;
}
.answerPage/deep/table tr:first-child {
  font-weight: 600;
}
.activity-button {
  width: 100% !important;
}
.answerPage {
  .active {
    .box-card-left,
    .box-card-right {
      height: 670px;
    }
  }
}
</style>
