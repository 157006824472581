<!-- 班级主页 -->
<template>
  <div class="homePage">
    <screen @searchClassButton="searchClassId($event)" @classData="classData"></screen>
    <div class="box-card">
      <!-- 左侧 -->
      <el-card class="box-card-left">
        <div style="height: 100%; position: relative">
          <span class="class-room-text">{{ classRoomText }}</span>
          <el-tabs v-model="completionStatus">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane label="已完成" name="已完成"></el-tab-pane>
            <el-tab-pane label="未完成" name="未完成"></el-tab-pane>
          </el-tabs>
          <div v-fence="{ data: '', functions: ['创建活动'] }" class="item">
            <el-button @click="addActivity(ruleForm)" type="primary" size="mini">创建活动</el-button>
          </div>
          <el-empty
            description="暂无活动!"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
            v-if="activityList.length == 0"
          ></el-empty>
          <activity-Task
            @editTask="editTask"
            :activityList="activityList"
            :completionStatus="completionStatus"
            @del_Activity="del_Activity"
          ></activity-Task>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="activeTotal"
            :current-page="activeCurrent"
            :page-size="pagesize"
            @current-change="activeData"
          ></el-pagination>
        </div>
      </el-card>
      <!-- 右侧 -->
      <div class="resize"></div>
      <el-card class="box-card-right">
        <activitie-Tree :activityList="activityTree" v-if="showTree"></activitie-Tree>
      </el-card>
      <!-- 创建活动弹窗 -->
      <el-dialog
        title="创建新的教学活动"
        :visible.sync="centerDialogVisible"
        width="60%"
        :before-close="handleClose"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="活动标题：" prop="activityTitle" class="input">
            <el-input
              v-model="ruleForm.activityTitle"
              placeholder="2-50个字符"
              maxlength="50"
              show-word-limit
              size="mini"
              width="10%"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动类型：" prop="activityValue" class="select">
            <el-select
              v-model="ruleForm.activityValue"
              placeholder="请选择"
              size="mini"
              @change="choiceActivity"
            >
              <el-option
                v-for="item in activityType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="Thespecialvariant"
            :prop="!showlabel ? 'tagder' : 'cascader'"
            class="select"
            v-if="
              ruleForm.activityValue !== '3' && ruleForm.activityValue !== '4'
            "
          >
            <el-cascader
              id="aaa"
              :options="variantType"
              v-model="ruleForm.cascader"
              :props="props"
              clearable
              size="mini"
              ref="cascader"
              v-if="showCascader && showlabel && ruleForm.activityValue !== '3'"
              :show-all-levels="false"
              @change="node($event)"
              @visible-change="elCascaderOnlick"
              @expand-change="elCascaderOnlick"
            ></el-cascader>
            <!-- 标签的题库   :options="tagOptions" -->
            <el-cascader
              v-model="ruleForm.tagder"
              :props="props"
              :options="tagOptions"
              placeholder="试试搜索：单选"
              collapse-tags
              clearable
              v-if="!showlabel"
              size="mini"
              :show-all-levels="true"
              filterable
              @visible-change="elCascaderOnlick"
              @expand-change="elCascaderOnlick"
              ref="reference"
            ></el-cascader>
            <el-cascader
              :options="variantType"
              v-model="ruleForm.cascader"
              :show-all-levels="false"
              v-if="
                !showCascader && showlabel && ruleForm.activityValue !== '3'
              "
              size="mini"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            :label="Thespecialvariant"
            prop="chapterValue"
            class="select"
            v-if="ruleForm.activityValue == '3'"
          >
            <!-- 视频的章节 -->
            <el-select
              v-model="ruleForm.chapterValue"
              placeholder="请选择"
              size="mini"
              @change="changeChapter"
            >
              <el-option
                v-for="item in variantType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="视频列表："
            prop="videoValue"
            class="select"
            v-if="ruleForm.activityValue == 3"
          >
            <el-select v-model="ruleForm.videoValue" placeholder="请选择" size="mini">
              <el-option
                v-for="item in videoList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="试卷列表："
            prop="testValue"
            class="select"
            v-if="ruleForm.activityValue == 4"
          >
            <el-select v-model="ruleForm.testvalue" placeholder="请选择" size="mini">
              <el-option
                v-for="item in testData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期：" prop="startDate" class="block">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.startDate"
              type="date"
              placeholder="选择日期"
              size="mini"
            ></el-date-picker>
          </el-form-item>
          <!-- //标签模板 -->
          <el-form-item v-if="!showlabel" label="标签模板：" class="select" prop="pitchonLabel">
            <el-select
              v-model="ruleForm.pitchonLabel"
              size="mini"
              placeholder="请选择"
              @change="
                (val) => {
                  Selectthelabelval(val, 2);
                }
              "
            >
              <el-option
                v-for="item in Thelabeltemplate"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!showlabel" label="参考答案：" class="select">
            <el-select
              :disabled="!ruleForm.pitchonLabel"
              v-model="ruleForm.referenceVersionValue"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in referenceVersionData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="结束日期：" prop="endDate" class="block">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.endDate"
              type="date"
              placeholder="选择日期"
              size="mini"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动启用：" class="switch">
            <el-switch
              v-model="ruleForm.showSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="activeState($event)"
              :disabled="showOpan"
            ></el-switch>
            {{ state[0] }}
          </el-form-item>
          <!-- showlabel 是  为k12 -->
          <el-form-item class="activity-type" v-if="showlabel">
            <el-checkbox v-model="ruleForm.checked" @change="showType">互动活动</el-checkbox>
            <el-radio
              v-model="ruleForm.radio"
              label="1"
              :disabled="showRadio"
              @change="showRadioType"
            >PK活动</el-radio>
            <el-radio
              v-model="ruleForm.radio"
              label="2"
              :disabled="showRadio"
              @change="showRadioType"
            >帮扶活动</el-radio>
          </el-form-item>
          <el-form-item label="是否紧急：" class="switch" v-if="showlabel">
            <el-switch
              v-model="ruleForm.urgent"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="activeUrgent($event)"
            ></el-switch>
            {{ state[1] }}
          </el-form-item>
          <el-form-item label="活动描述：" prop="textDescribe" class="textarea">
            <el-input
              type="textarea"
              v-model="ruleForm.textDescribe"
              placeholder="1-200个字符"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="活动指导：" prop="textGuidance" class="textarea">
            <el-input
              type="textarea"
              v-model="ruleForm.textGuidance"
              placeholder="1-200个字符"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item class="classification" @change="choiceRadio" v-if="showTransfer">
            选择参与学生：
            <el-radio v-model="ruleForm.radioParticipate" label="1" @change="choiceRadio">按姓名</el-radio>
            <el-radio v-model="ruleForm.radioParticipate" label="2" @change="choiceRadio">按群组</el-radio>
          </el-form-item>
          <el-form-item class="transferValue" v-if="showTransfer">
            <el-transfer :titles="['未选中', '已选中']" v-model="ruleForm.transferValue" :data="data"></el-transfer>
          </el-form-item>
          <el-form-item
            label="帮扶人："
            v-show="!showTransfer"
            class="helpType"
            v-for="(item, index) in helpTypeOptionsForm"
            :key="index"
          >
            <el-select
              v-model="item.helpType"
              placeholder="请选择"
              @change="choiseHelp(index, item.helpType)"
            >
              <el-option
                v-for="itm in item.helpTypeOptions"
                :key="itm.key"
                :label="itm.label"
                :value="itm.key"
                :disabled="itm.disabled"
              ></el-option>
            </el-select>
            <span class="span-help">被帮扶人：</span>
            <el-select
              v-model="item.behelpType"
              placeholder="请选择"
              @change="choiseHelp(index, item.behelpType)"
            >
              <el-option
                v-for="itm in item.behelpTypeOptions"
                :key="itm.key"
                :label="itm.label"
                :value="itm.key"
                :disabled="itm.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="operationDomain(0)" v-if="!showTransfer" type="primary">新增帮扶小组</el-button>
            <el-button @click="operationDomain(1)" v-if="!showTransfer" type="danger">删除帮扶小组</el-button>
          </el-form-item>
          <el-form-item class="el-form-button">
            <span class="span-button">
              <el-button
                type="primary"
                @click="createActivity('ruleForm')"
                :disabled="!throttle"
              >提 交</el-button>
              <el-button plain @click="preview('ruleForm')" v-if="ruleForm.activityValue !== '3'">预览</el-button>
              <el-button @click="cancel">取 消</el-button>
            </span>
          </el-form-item>
        </el-form>
        <!-- k12预览弹窗 -->
        <div class="showPreview" v-if="showPreview && ruleForm.activityValue == 0">
          <previewSubject
            @editOut="
              () => {
                this.showPreview = false;
              }
            "
            :yuLanObj="yuLanObj"
          ></previewSubject>
        </div>
        <!-- immex预览弹窗 -->
        <div class="showPreview" v-if="showPreview && ruleForm.activityValue == 1">
          <previewImmex
            @editOut="
              () => {
                this.showPreview = false;
              }
            "
            :yuLanObj="yuLanObj"
          ></previewImmex>
        </div>
        <!-- 标签预览弹窗 -->
        <div class="showPreview" v-if="showPreview && ruleForm.activityValue == 2">
          <previewLabel
            @editOut="
              () => {
                this.showPreview = false;
              }
            "
            :yuLanObj="yuLanObj"
          ></previewLabel>
        </div>
      </el-dialog>
      <!-- 编辑活动弹窗 -->
      <el-dialog
        title="编辑教学活动"
        :visible.sync="editDialogVisible"
        width="60%"
        :before-close="handleClose"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="活动标题：" prop="activityTitle" class="input">
            <el-input
              v-model="ruleForm.activityTitle"
              placeholder="2-50个字符"
              maxlength="50"
              show-word-limit
              size="mini"
              width="10%"
            ></el-input>
          </el-form-item>
          <el-form-item :label="Thespecialvariant" prop="cascader" class="select" v-if="show_edit">
            <el-cascader
              :options="variantType"
              v-model="ruleForm.cascader"
              :props="props"
              clearable
              size="mini"
              ref="cascader"
              v-if="showCascader && showlabel"
              :show-all-levels="false"
              @change="node($event)"
              @visible-change="elCascaderOnlick"
              @expand-change="elCascaderOnlick"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="开始日期：" prop="startDate" class="block">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.startDate"
              type="date"
              placeholder="选择日期"
              size="mini"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期：" prop="endDate" class="block">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.endDate"
              type="date"
              placeholder="选择日期"
              size="mini"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="活动启用：" class="switch">
            <el-switch
              v-model="ruleForm.showSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="activeState($event)"
              :disabled="show_edit_switch"
            ></el-switch>
            {{ state[0] }}
          </el-form-item>
          <el-form-item label="活动描述：" prop="textDescribe" class="textarea">
            <el-input
              type="textarea"
              v-model="ruleForm.textDescribe"
              placeholder="1-200个字符"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="活动指导：" prop="textGuidance" class="textarea">
            <el-input
              type="textarea"
              v-model="ruleForm.textGuidance"
              placeholder="1-200个字符"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            class="classification"
            @change="choiceRadio"
            v-if="showTransfer && show_edit"
          >
            选择参与学生：
            <el-radio v-model="ruleForm.radioParticipate" label="1" @change="choiceRadio">按姓名</el-radio>
            <el-radio v-model="ruleForm.radioParticipate" label="2" @change="choiceRadio">按群组</el-radio>
          </el-form-item>
          <el-form-item class="transferValue" v-if="showTransfer && show_edit">
            <el-transfer :titles="['未选中', '已选中']" v-model="ruleForm.transferValue" :data="data"></el-transfer>
          </el-form-item>
          <el-form-item class="el-form-button">
            <span class="span-button">
              <el-button type="primary" @click="editActivity(0, 'ruleForm')">编 辑</el-button>
              <el-button @click="editActivity(1)">取 消</el-button>
            </span>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  videoList,
  activeList,
  activeDetails,
  getIMXTask,
  getSpecialtopic,
  activeAdd,
  groupList,
  GetStudentByGradeClass,
  activeUpdate,
  templateList,
  resourceList,
  examList,
  getSpecialtopicVideo,
  tagActivitySpecialist,
  allKeyword,
  keywordTopic
} from "../../api/api";
import { drag } from "../../util/drag.js";
import screen from "@/components/screen";
import activityTask from "@/components/activityTask";
import activitieTree from "@/components/activitieTree";
import previewSubject from "@/components/previewSubject";
import previewImmex from "@/components/previewImmex";
import previewLabel from "@/components/previewLabel";
import addActivity from "../../components/addActivity/index.vue";
export default {
  components: {
    screen,
    activityTask,
    activitieTree,
    previewSubject,
    previewImmex,
    previewLabel,
    addActivity,
  },
  data () {
    let validateStartTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择开始时间"));
      } else {
        if (this.ruleForm.endDate) {
          this.$refs.ruleForm.validateField("endDate");
        }
        callback();
      }
    };
    let validateEndTime = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!this.ruleForm.startDate) {
          callback(new Error("请选择开始时间！"));
        } else if (Date.parse(this.ruleForm.startDate) >= Date.parse(value)) {
          callback(new Error("结束时间必须大于开始时间！"));
        } else {
          callback();
        }
      }
    };
    return {
      resourcecatalog: [], //资源目录
      Thespecialvariant: "专题变式：", //专题变式
      Thelabeltemplate: [
        //标签模板列表
      ],
      tagOptions: [],
      referenceVersionData: [], //标签参考答案
      activeTotal: 0,
      activeCurrent: 1,
      pagesize: 10,
      cascadeData: [],
      identity: null,
      state: {
        0: "启用",
        1: "已关闭",
      },
      showPreview: false,
      data: [],
      classRoomText: "",
      completionStatus: "all",
      activityList: {},
      activityTree: {},
      centerDialogVisible: false,
      showRadio: true,
      activityType: [], //活动类型
      props: {
        multiple: true,
        lazy: true,
        lazyLoad: this.lazy_load
      },
      variantType: [],
      ruleForm: {
        activityTitle: null,
        cascader: [],
        tagder: [],
        activityValue: null,
        startDate: null,
        endDate: "",
        showSwitch: true,
        checked: false,
        radio: "0",
        textDescribe: null,
        textGuidance: null,
        transferValue: [],
        radioParticipate: "1",
        urgent: false,
        pitchonLabel: null, //选中的标签
        videoValue: "", //选中的视频
        chapterValue: "", //选中的章节
        testvalue: "", //选中的试卷
        referenceVersionValue: null, //选中的参考答案
      },
      rules: {
        activityTitle: [
          { required: true, message: "请填写活动标题", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
        activityValue: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        chapterValue: [
          { required: true, message: "请选择章节", trigger: "change" },
        ],
        videoValue: [
          { required: true, message: "请选择视频", trigger: "change" },
        ],
        cascader: [
          { required: true, message: "请选择专题变式", trigger: "change" },
        ],
        tagder: [{ required: true, message: "请选择题目", trigger: "change" }],
        pitchonLabel: [
          { required: true, message: "请选择标签模板", trigger: "change" },
        ],
        referenceversion: [
          { required: true, message: "请选择参考答案", trigger: "change" },
        ],
        startDate: [
          { required: true, validator: validateStartTime, trigger: "blur" },
        ],
        endDate: [
          { required: false, validator: validateEndTime, trigger: "blur" },
        ],
        textDescribe: [
          { required: true, message: "请填写活动描述", trigger: "blur" },
        ],
        textGuidance: [
          { required: true, message: "请填写活动指导", trigger: "blur" },
        ],
      },
      showSwitch: 1,
      showTransfer: true,
      showCascader: true,
      showOpan: false,
      helpType: null,
      helpTypeOptionsForm: [
        {
          helpTypeOptions: [],
          key: 1,
          helpType: null,
          behelpTypeOptions: [],
          behelpType: null,
        },
      ],
      behelpType: null,
      groupData: [],
      studentData: [],
      helpStudentData: [],
      classOptions: [],
      searchId: null,
      showCurrent: true,
      editDialogVisible: false,
      editId: null,
      showlabel: true, //是否显示不选中标签的内容
      TABactivitylistdetails: null, //标签活动列表详情
      throttle: true,
      status: 0, //0  老师  1  学生
      show_edit: false, //编辑窗口的变式或者学生栏是否显示
      show_edit_switch: false, //编辑窗口的启用按钮是否禁用
      videoList: "", //视频列表
      chapterList: [], //视频的章节列表
      testData: [], //测验列表,
      difficulty: [], //选中的难度字段
      showTree: true, //是否显示树
      scrollBottom: null,
      f_scrollBottom: null,
      loadscroll: true,
      lazy_pageSize: 20,
      lazy_pageNum: 1,
      menu_node: null,
      menu_resolve: null
    };
  },
  created () { },
  watch: {
    referenceVersionData (val, oldVal) {
      if (val == null) {
        this.ruleForm.referenceVersionValue = null;
      }
    },
    // 这里监听 scrollBottom 等于0触发获取数据的方法 否则不触发 
    scrollBottom (val) {
      // console.log('我是watch监听的距离底部的距离', val)
      let f_height = this.f_scrollBottom + 50
      if (val < f_height) {
        if (this.loadscroll) {
          this.loadscroll = false
          this.lazy_pageNum = this.lazy_pageNum + 1
          this.lazy_load(this.menu_node, this.menu_resolve)
        }
      }
    },
  },
  mounted () {
    drag.dragControllerDiv(
      "resize",
      "box-card-left",
      "box-card-right",
      "box-card"
    );
    if (this.$route.query.idType) {
      this.completionStatus = this.$route.query.idType;
    }
    this.analysis();
  },
  methods: {
    hanleScroll () {
      let menu = document.querySelector('.el-cascader-panel').childNodes[1].getBoundingClientRect().bottom
      let list = document.querySelector('.el-cascader-panel').childNodes[1].querySelector('.el-cascader-menu__list').getBoundingClientRect().bottom
      this.f_scrollBottom = menu
      this.scrollBottom = list
    },
    // 级联动态加载
    lazy_load (node, resolve) {
      if (node.level == 2) {
        node.loading = false
      }
      this.menu_node = node
      this.menu_resolve = resolve
      const { label } = node;
      if (label) {
        keywordTopic({
          "pageNum": this.lazy_pageNum,
          "pageSize": 20,
          "gradeId": this.activityTree.grade,
          "subjectId": this.activityTree.subjects,
          "keyword": label == '未分类' ? 'NULL' : label
        }).then(res => {
          const nodes = []
          if (res.code == 0) {
            for (let item of res.data.list) {
              nodes.push({
                value: item.id,
                label: item.name,
                leaf: true
              })
            }
            document.querySelector('.el-cascader-panel').addEventListener('scroll', this.hanleScroll, true)
            this.loadscroll = true
            resolve(nodes);
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    //级联分页
    handleCurrentChange (val) { },
    // 选中视频的章节
    changeChapter (val) {
      let param = {
        pageNum: null,
        pageSize: null,
        specialTopicId: val, //变式id
      };
      videoList(param).then((res) => {
        if (res.code == 0) {
          this.videoList = res.data.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除活动刷新列表
    del_Activity (val) {
      this.loadActiveList(val);
    },
    //标签选中
    Selectthelabelval (val, type) {
      // template_id 标签模板id ruleForm.pitchonLabel   cascader
      this.getReferenceVersiondata();
    },
    //获取标签参考答案列表
    getReferenceVersiondata () {
      var list = [];
      this.ruleForm.tagder.map((item, index) => {
        list.push(this.ruleForm.tagder[index][1]);
      });

      tagActivitySpecialist({
        topicArray: list,
        labelId: this.ruleForm.pitchonLabel,
      }).then((res) => {
        if (res.code == 0) {
          this.referenceVersionData = res.data;
          this.ruleForm.referenceVersionValue = this.referenceVersionData[0].id;
        }
      });
    },
    // 活动分页
    activeData (val) {
      this.activeCurrent = val;
      this.loadActiveList(this.$route.query.classId);
    },
    // 解析appIds
    analysis () {
      if (
        this.utils.identity("K12做题") ||
        this.utils.identity("IMX做题") ||
        this.utils.identity("提交参考答案") || this.utils.identity("提交答案")
      ) {
        this.status = 1;
      }
      if (this.utils.identity("新建标签活动")) {
        //去掉树状图
        this.showTree = false;
        this.activityType.push({
          label: "标签",
          value: "2",
        });
      }
      if (this.utils.identity("提交答案")) {
        //去掉树状图
        this.showTree = false;
      }
      if (this.utils.identity("提交参考答案")) {
        //去掉树状图
        this.showTree = false;
      }
      if (this.utils.identity("标签活动列表详情")) {
        this.TABactivitylistdetails = "1";
      }
      if (this.utils.identity("新建K12活动")) {
        this.activityType.push({
          label: "k12",
          value: "0",
        });
      }
      if (this.utils.identity("创建视频活动")) {
        this.activityType.push({
          label: "视频",
          value: "3",
        });
      }
      if (this.utils.identity("新建IMMEX活动")) {
        this.activityType.push({
          label: "IMMEX",
          value: "1",
        });
      }
      if (this.utils.identity("创建小测验活动")) {
        this.activityType.push({
          label: "小测验",
          value: "4",
        });
      }
    },
    // 编辑活动
    editActivity (type, formName) {
      // 0 编辑  1 取消编辑
      if (type == 0) {
        this.$refs[formName].validate((valid) => {
          let arr = [];
          if (this.ruleForm.cascader) {
            this.ruleForm.cascader.map((item, index) => {
              let obj = {};
              if (this.difficulty.length > 0) {
                obj["difficulty"] = this.difficulty.find((difficulty_item) => {
                  return (
                    difficulty_item.data.id == this.ruleForm.cascader[index][1]
                  );
                }).data.difficulty;
              }
              obj["caseId"] = this.ruleForm.cascader[index][1];
              obj["sort"] = index + 1;
              obj["stemType"] = Number(this.ruleForm.activityValue);
              arr.push(obj);
            });
          }
          if (valid) {
            this.ruleForm.showSwitch
              ? (this.showSwitch = 1)
              : (this.showSwitch = 0);
            let param = {
              classId: this.$route.query.classId,
              id: this.editId,
              stemType: Number(this.ruleForm.activityValue),
              description: this.ruleForm.textDescribe, //活动描述
              teachingMessage: this.ruleForm.textGuidance, //活动指导
              isStart: Number(this.showSwitch), //是否开启0：关闭 1：开启
              title: this.ruleForm.activityTitle, //活动标题
              userIds: this.ruleForm.transferValue, //选中的学生
              stems: arr, //选中的变式
              endTime: this.ruleForm.endDate, //结束时间--
              startTime: this.ruleForm.startDate, //开始时间
            };
            activeUpdate(param).then((res) => {
              this.loadActiveList(this.searchId.id);
              if (res.code == 0) {
                this.$message({
                  message: "编辑成功！",
                  type: "success",
                });
                let userId = Number(this.utils.userId());
                _hmt.push([
                  "_trackEvent",
                  "成功编辑活动",
                  "编辑活动",
                  "活动:" + this.ruleForm.activityTitle,
                  userId,
                ]);
              }
              this.editDialogVisible = false;
            });
          }
        });
      } else if (type == 1) {
        this.ruleForm.activityTitle = null;
        this.ruleForm.showSwitch = true;
        this.ruleForm.textDescribe = null;
        this.ruleForm.textGuidance = null;
        this.editDialogVisible = false;
        this.cancel();
      }
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
    },
    // 点击编辑活动按钮
    editTask (val) {
      this.show_edit_switch = !val.isOpen;
      this.ruleForm.cascader = [];
      if (val.isStart == 0) {
        this.state[0] = "停止";
      }
      if (val.isStart == 1) {
        this.ruleForm.showSwitch = true;
      } else {
        this.ruleForm.showSwitch = false;
      }
      this.choiceActivity(0);
      activeDetails({ id: val.id }).then((res) => {
        if (res.code == 0) {
          let stems = [];
          let userId = [];
          res.data.stems.forEach((item) => {
            let arr = [];
            arr.push(item.setID);
            arr.push(item.caseId);
            stems.push(arr);
          });
          res.data.users.forEach((item) => {
            userId.push(item.userId);
          });
          this.ruleForm.activityValue = val.stemType;
          this.ruleForm.cascader = stems;
          this.ruleForm.radioParticipate = "1";
          this.choiceRadio(1);
          this.ruleForm.transferValue = userId;
          this.ruleForm.endDate = val.endTime ? val.endTime + " 00:00:00" : ""; //结束时间--
          this.ruleForm.startDate = val.startTime.input
            ? val.startTime.input
            : ""; //开始时间
        } else {
          this.$message.error(res.message);
        }
      });
      this.show_edit = !val.isOpen;
      this.ruleForm.activityTitle = val.title;
      this.ruleForm.textDescribe = val.description;
      this.ruleForm.textGuidance = val.teachingMessage;
      this.editId = val.id;
      this.editDialogVisible = true;
    },
    // 帮扶选中
    choiseHelp (index, id) {
      let arr = [];
      this.helpTypeOptionsForm.map((itm) => {
        if (itm.helpType !== null) {
          arr.push(itm.helpType);
        }
        if (itm.behelpType !== null) {
          arr.push(itm.behelpType);
        }
      });
      this.helpStudentData.map((item) => {
        item.disabled = false;
        arr.map((itm) => {
          if (item.id == itm) {
            item.disabled = true;
          }
        });
      });
      this.helpTypeOptionsForm.map((item) => {
        item.helpTypeOptions = this.helpStudentData;
        item.behelpTypeOptions = this.helpStudentData;
      });
    },
    // 动态增加/删除下拉框
    operationDomain (type) {
      // 0 增加  1 删除
      if (type == 0) {
        if (
          this.helpTypeOptionsForm.length <
          parseInt(this.helpStudentData.length / 2)
        ) {
          this.helpTypeOptionsForm.push({
            helpTypeOptions: this.helpStudentData,
            behelpTypeOptions: this.helpStudentData,
            key: Date.now(),
          });
        } else {
          this.$message({
            message: "剩余学生数量不支持帮扶!",
            type: "warning",
          });
        }
      } else if (type == 1) {
        if (this.helpTypeOptionsForm.length !== 1) {
          let i = this.helpTypeOptionsForm.length - 1;
          let arr = [];
          arr.push(this.helpTypeOptionsForm[i].behelpType);
          arr.push(this.helpTypeOptionsForm[i].helpType);
          this.helpStudentData.map((item) => {
            arr.map((itm) => {
              if (item.id == itm) {
                item.disabled = false;
              }
            });
          });
          this.helpTypeOptionsForm.splice(i, 1);
        } else {
          this.$message({
            message: "至少保留一组帮扶学生!",
            type: "warning",
          });
        }
      }
    },
    // 选中的班级详情
    searchClassId (val) {
      this.classRoomText = val.className;
      this.searchId = val;
      this.choiceSelect(val.id);
      this.loadActiveList(val.id);
      this.loadStudent(val.id);
      let userId = Number(this.utils.userId());
      _hmt.push(["_trackEvent", "活动列表", "查看", "班级:" + val, userId]);
    },
    // 设置点击文本选中
    elCascaderOnlick () {
      setTimeout(function () {
        document.querySelectorAll(".el-cascader-menu").forEach((el, index) => {
          if (index == 1) {
            el.querySelectorAll(".el-cascader-node__label").forEach((el1) => {
              el1.onclick = function () {
                this.previousElementSibling.click();
              };
            });
          }
        });
      }, 100);
    },
    //
    node (val) {
      if (this.cascadeData.length > 0) {
        this.cascadeData.map((item) => {
          val.map((itm) => {
            if (item !== itm[1]) {
              this.cascadeData.push(itm[1]);
              this.cascadeData = [...new Set(this.cascadeData)];
            }
          });
        });
        let arr = [];
        let arr1 = [];
        this.cascadeData.map((item) => {
          val.map((itm) => {
            if (item == itm[1]) {
              arr.push(itm[1]);
            }
          });
          this.ruleForm.cascader.map((it) => {
            if (item == it[1]) {
              arr1.push(it);
            }
          });
        });
        this.cascadeData = arr;
        this.ruleForm.cascader = arr1;
      } else {
        this.cascadeData.push(val[0][1]);
      }
      this.difficulty = this.$refs["cascader"].getCheckedNodes();
    },
    // 活动启用开启状态
    activeState (val) {
      if (!val) {
        this.state[0] = "停止";
      } else {
        this.state[0] = "启用";
      }
    },
    // 紧急活动开启状态
    activeUrgent (val) {
      if (!val) {
        this.state[1] = "已关闭";
        this.showOpan = false;
      } else {
        this.state[1] = "已开启";
        this.state[0] = "启用";
        this.showOpan = true;
        this.ruleForm.showSwitch = true;
      }
    },
    // 搜索传来的值  班级列表
    classData (val) {
      let data = val.find((item) => {
        return item.id == this.$route.query.classId;
      });
      this.activityTree = data;
      this.classOptions = val;
    },
    // 获取当前班级下的学生
    loadStudent (val) {
      var isUser = 1;
      if (this.TABactivitylistdetails) {
        //有为1，代表教授
        isUser = 2;
      }
      GetStudentByGradeClass({ classId: val, isUser: isUser }).then((res) => {
        res.data.list.map((item) => {
          item["key"] = item.id;
          item["label"] = item.fullName;
        });
        this.studentData = res.data.list;
        this.helpStudentData = res.data.list;
        this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
        this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
      });
    },
    // 获取活动列表
    async loadActiveList (val) {
      //val  当前班级id
      await activeList({
        pageNum: this.activeCurrent,
        pageSize: this.pagesize,
        classId: val,
        stemType: null, //题库类型  0:k12  1:immx
      }).then((res) => {
        if (res.data) {
          this.activeTotal = res.data.total;
          if (res.data.list.length !== 0) {
            // -------------判断是否最紧急
            let itarget = null;
            if (this.status == 1) {
              itarget = res.data.list.find((item) => {
                return item.priority == 1 && item.allTask !== item.completeTask;
              });
              if (itarget) {
                itarget["existence"] = true;
              }
            } else if (this.status == 0) {
              res.data.list.map((item) => {
                if (item.priority == 1 && item.allUser !== item.completeUser) {
                  item["existence"] = true;
                }
              });
            }
            res.data.list.sort((a, b) => {
              return +a.createTime > +b.createTime ? "1" : "-1";
            });
            let current = 0;
            let minTimeDiff = Math.abs(
              new Date(res.data.list[0].startTime).getTime() -
              new Date().getTime()
            );
            for (let i = 0; i < res.data.list.length; i++) {
              let currentTimeDiff = Math.abs(
                new Date(res.data.list[i].startTime).getTime() -
                new Date().getTime()
              );
              if (currentTimeDiff < minTimeDiff) {
                minTimeDiff = currentTimeDiff;
                current = i;
              }
            }
            res.data.list[current]["current"] = true;
            // ---------------------
            res.data.list = res.data.list.sort((a, b) => {
              return a.startTime < b.startTime ? 1 : -1;
            });
            res.data.list.map((item) => {
              item.startTime = /\d{4}-\d{1,2}-\d{1,2}/g.exec(item.startTime);
              if (this.status == 0) {
                if (item.completeUser > 0) {
                  let a = (item.completeUser / item.allUser).toFixed(2);
                  if (item.type == 2) {
                    a = a * 2;
                  }
                  item["completion"] = Math.round(a * 100);
                } else {
                  item["completion"] = 0;
                }
              } else {
                if (item.completeTask > 0) {
                  let a = (item.completeTask / item.allTask).toFixed(2);
                  item["completion"] = Math.round(a * 100);
                } else {
                  item["completion"] = 0;
                }
              }
              let date = this.currentTime.currentTime();
              let time_status = false;
              if (date < item.startTime[0] || date > item.endTime) {
                time_status = true;
              }
              if (time_status && item.completion !== 100) {
                item["isOpen"] = false;
              } else {
                item["isOpen"] = true;
              }
              if (item.endTime) {
                item.endTime = item.endTime.split(" ")[0];
              }
            });
            this.activityList = res.data.list;
          } else {
            this.activityList = res.data.list;
          }
        }
      });
    },
    // 判断活动类型
    showType () {
      this.showRadio = !this.showRadio;
      this.showTransfer = true;
      this.helpStudentData.map((item) => {
        item.disabled = false;
      });
      if (this.showRadio) {
        this.ruleForm.radio = "0";
      } else {
        this.ruleForm.radio = "1";
      }
    },
    showRadioType (val) {
      if (val == 2) {
        this.helpStudentData.map((item) => {
          item.disabled = false;
        });
        this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
        this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
        this.showTransfer = false;
      } else {
        this.helpTypeOptionsForm = [
          {
            helpTypeOptions: [],
            key: 1,
            helpType: null,
            behelpTypeOptions: [],
            behelpType: null,
          },
        ];
        this.helpStudentData.map((item) => {
          item.disabled = false;
        });
        this.showTransfer = true;
      }
    },
    // 新增活动获取数据
    addActivity () {
      this.variantType = [];
      this.ruleForm.activityTitle = null;
      this.ruleForm.cascader = [];
      this.ruleForm.showSwitch = true;
      this.ruleForm.textDescribe = null;
      this.ruleForm.textGuidance = null;
      this.showPreview = false;
      this.centerDialogVisible = true;
      this.choiceRadio(1);
    },
    // 创建活动选中类型
    choiceActivity (val) {
      this.variantType = [];
      this.ruleForm.cascader = [];
      let param = {};
      //0是k12  1 imx  2 标签  3 视频  4 试卷
      if (val == "0") {
        this.showlabel = true; //显示互动
        this.Thespecialvariant = "专题变式：";
        this.showCascader = true;
        param = {
          grade: this.activityTree.grade,
          subject: this.activityTree.subjects,
          specialtopicType: Number(val),
        }; //获取k12的数据
        GetStudentByGradeClass({
          classId: this.$route.query.classId,
          isUser: 1,
        }).then((res) => {
          res.data.list.map((item) => {
            item["key"] = item.id;
            item["label"] = item.fullName;
          });
          this.studentData = res.data.list;
          this.helpStudentData = res.data.list;
          this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
          this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
          this.choiceRadio(1);
        });
        this.loadSpecialtopic(param);
      } else if (val == "1") {
        this.Thespecialvariant = "专题变式：";
        this.showlabel = true; //显示互动
        this.showCascader = false;
        param = {
          grade: this.activityTree.grade,
          subject: 12,
          // specialtopicType: Number(val),
        };
        GetStudentByGradeClass({
          classId: this.$route.query.classId,
          isUser: 1,
        }).then((res) => {
          res.data.list.map((item) => {
            item["key"] = item.id;
            item["label"] = item.fullName;
          });
          this.studentData = res.data.list;
          this.helpStudentData = res.data.list;
          this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
          this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
          this.choiceRadio(1);
        });
        getIMXTask(param).then((res) => {
          if (res.code == 0) {
            res.data.map((item) => {
              item["label"] = item.chapterName;
              item["children"] = item.list;
              item.children.map((itm, index) => {
                itm["label"] = item.chapterName + itm.caseName;
              });
            });
            let arr = [];
            res.data.forEach((item) => {
              item["value"] = item.id;
              item.children.map((itm) => {
                itm["value"] = itm.id;
                itm.children = undefined;
              });
              arr.push(item);
            });
            this.variantType = arr;
          }
        });
      } else if (val == "2") {
        //选择了标签  this.searchId.id班级id
        this.Thespecialvariant = "选择题目：";
        this.showlabel = false; //显示标签下的所有内容
        //获取标签模板列表  需要传入当前年级和科目
        templateList({
          gradeId: this.activityTree.grade,
          subjectId: this.activityTree.subjects,
        }).then((res) => {
          this.Thelabeltemplate = res.data.list;
        });
        allKeyword({
          pageSize: null,
          pageNum: null,
          gradeId: this.activityTree.grade,
          subjectId: this.activityTree.subjects,
        }).then((res) => {
          let arr = []
          // arr.push({
          //   label: '未分类',
          //   value: 0,
          //   children: []
          // })
          res.data.map((item, index) => {
            arr.push({
              label: item == 'NULL' ? '未分类' : item,
              value: index,
              children: []
            })
          });

          this.tagOptions = arr;
          //获取题库资源列表   需要传入当前年级和科目
          this.resourcecatalog = res.data.list;
        });
        GetStudentByGradeClass({
          classId: this.$route.query.classId,
          isUser: 2,
        }).then((res) => {
          res.data.list.map((item) => {
            item["key"] = item.id;
            item["label"] = item.fullName;
          });
          this.studentData = res.data.list;
          this.helpStudentData = res.data.list;
          this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
          this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
          this.choiceRadio(1);
        });
      } else if (val == "3") {
        this.Thespecialvariant = "专题变式：";
        this.showCascader = true;
        param = {
          grade: this.activityTree.grade,
          subject: this.activityTree.subjects,
          specialtopicType: 0,
        }; //获取k12的数据
        GetStudentByGradeClass({
          classId: this.$route.query.classId,
          isUser: 1,
        }).then((res) => {
          res.data.list.map((item) => {
            item["key"] = item.id;
            item["label"] = item.fullName;
          });
          this.studentData = res.data.list;
          this.helpStudentData = res.data.list;
          this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
          this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
          this.choiceRadio(1);
        });
        getSpecialtopicVideo(param).then((res) => {
          if (res.code == 0) {
            let arr = [];
            res.data.map((item) => {
              item["label"] = item.chapterTitle;
              item["value"] = item.id;
              arr.push(item);
            });
            this.variantType = arr;
          }
        });
      } else if (val == "4") {
        this.showlabel = true; //显示互动
        this.Thespecialvariant = "试卷列表：";
        this.showCascader = true;
        examList({
          gradeId: this.activityTree.grade,
          subjectId: this.activityTree.subjects,
        }).then((res) => {
          //获取试卷资源列表   需要传入当前年级和科目
          this.testData = res.data.list;
          this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
          this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
          this.choiceRadio(1);
        });
      }
    },
    // 加载K12章节题目列表
    loadSpecialtopic (param) {
      getSpecialtopic(param).then((res) => {
        if (this.showCascader) {
          //当前是创建k12的
          res.data.map((item) => {
            item["label"] = item.chapterTitle;
            item["children"] = item.npCases;
            item.children.map((itm) => {
              itm["label"] = itm.caseSN;
            });
          });
          let arr = [];
          res.data.forEach((item) => {
            if (item.npCases.length > 0) {
              item["value"] = item.id;
              item.children.map((itm) => {
                itm["value"] = itm.id;
                itm.children = undefined;
              });
              arr.push(item);
            }
          });
          this.variantType = arr;
          //k12清空表单
          this.ruleForm.pitchonLabel = null;
        } else {
          res.data.map((item) => {
            item["label"] = item.psname;
            item["children"] = item.caseEntities;
            item.children.map((itm, index) => {
              itm["label"] = item.psname + itm.caseSN;
            });
          });
          let arr = [];
          res.data.forEach((item) => {
            item["value"] = item.id;
            item.children.map((itm) => {
              itm["value"] = itm.id;
              itm.children = undefined;
            });
            arr.push(item);
          });
          this.variantType = arr;
        }
      });
    },
    // 提交表单
    createActivity (formName) {
      // 帮扶数据
      let arr1 = null;
      let arr = [];
      let userArr = [];
      if (this.ruleForm.radio == 2) {
        let arr2 = [];
        this.helpTypeOptionsForm.forEach((item) => {
          let obj = {};
          obj["userId"] = item.helpType;
          obj["beUserId"] = item.behelpType;
          arr2.push(obj);
          userArr.push(item.helpType);
          userArr.push(item.behelpType);
        });
        arr1 = arr2;
      }
      // 判断是否完善帮扶人信息
      if (arr1 !== null) {
        arr1.map((item) => {
          if (item.beUserId == null || item.userId == null) {
            this.showCurrent = false;
          } else {
            this.showCurrent = true;
          }
        });
      }
      this.ruleForm.showSwitch ? (this.showSwitch = 1) : (this.showSwitch = 0);
      // 判断题型并拿出caseId
      if (Number(this.ruleForm.activityValue) == 0) {
        if (this.ruleForm.cascader) {
          this.ruleForm.cascader.map((item, index) => {
            let obj = {};
            obj["caseId"] = this.cascadeData[index];
            obj["sort"] = index + 1;
            obj["stemType"] = Number(this.ruleForm.activityValue);
            obj["difficulty"] = this.difficulty.find((difficulty_item) => {
              return difficulty_item.data.id == this.cascadeData[index];
            }).data.difficulty;
            arr.push(obj);
          });
        }
      } else if (Number(this.ruleForm.activityValue) == 1) {
        let obj = {};
        obj["caseId"] = this.ruleForm.cascader[1];
        obj["sort"] = 1;
        obj["stemType"] = Number(this.ruleForm.activityValue);
        arr.push(obj);
      } else if (Number(this.ruleForm.activityValue) == 2) {
        //是标签
        if (this.ruleForm.cascader == []) {
        } else {
          this.ruleForm.tagder.map((item, index) => {
            let obj = {};
            obj["caseId"] = this.ruleForm.tagder[index][1];
            obj["sort"] = index + 1;
            obj["templateId"] = this.ruleForm.pitchonLabel;
            obj["stemType"] = Number(this.ruleForm.activityValue);
            arr.push(obj);
          });
          arr = this.unique(arr);
        }
      } else if (Number(this.ruleForm.activityValue) == 3) {
        let obj = {};
        obj["caseId"] = this.ruleForm.videoValue;
        obj["sort"] = 1;
        obj["stemType"] = Number(this.ruleForm.activityValue);
        arr.push(obj);
      } else if (Number(this.ruleForm.activityValue) == 4) {
        //小测验
        let obj = {};
        obj["caseId"] = this.ruleForm.testvalue;
        obj["sort"] = 1;
        obj["stemType"] = Number(this.ruleForm.activityValue);
        arr.push(obj);
      }

      let a = {
        activityTitle: null,
        cascader: null,
        activityValue: null,
        startDate: null,
        endDate: "",
        showSwitch: true,
        checked: false,
        radio: "0",
        textDescribe: null,
        textGuidance: null,
        transferValue: [],
        radioParticipate: "2",
        urgent: false,
      };
      if (this.ruleForm.transferValue.length == 0) {
        this.$message.error("请选择参与学生");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是否紧急
          if (this.ruleForm.urgent) {
            this.ruleForm.urgent = "1";
          } else {
            this.ruleForm.urgent = "0";
          }
          let i = [];
          // 获取小组的学生
          this.groupData.map((item) => {
            this.ruleForm.transferValue.map((itm) => {
              if (item.id == itm) {
                item.list.map((it) => {
                  i.push(it.id);
                });
              }
            });
          });
          if (i.length !== 0) {
            i = [...new Set(i)];
            this.ruleForm.transferValue = i;
          }
          if (this.ruleForm.radio == 2) {
            this.ruleForm.transferValue = userArr;
          }
          if (this.searchId == null) {
            this.$message.error("请选择班级!");
          }
          let param = {
            //需要新加进去
            endTime: this.ruleForm.endDate, //结束时间--
            isStart: Number(this.showSwitch), //是否开启0：关闭 1：开启
            startTime: this.ruleForm.startDate, //开始时间
            description: this.ruleForm.textDescribe, //活动描述
            teachingMessage: this.ruleForm.textGuidance, //活动指导
            stems: arr,
            title: this.ruleForm.activityTitle, //活动标题
            type: Number(this.ruleForm.radio), //活动类型（0：一般；1：pk；2：帮扶）
            priority: Number(this.ruleForm.urgent), //优先级别（0：一般；1：高）
            userIds: this.ruleForm.transferValue,
            stemType: Number(this.ruleForm.activityValue), //题库类型  0: k12  1: immx, 2:标签
            classId: this.searchId.id, //班级id
            supportList: arr1, //帮扶配置
            templateId: this.ruleForm.pitchonLabel, //标签模板id----------------------------
            record_id: "", //标签记录id
            standardAnswer:
              this.ruleForm.referenceVersionValue == null
                ? 0
                : this.ruleForm.referenceVersionValue, //参考答案
          };
          if (this.showCurrent && this.throttle) {
            this.throttle = false;
            activeAdd(param).then((res) => {
              this.throttle = true;
              if (res.code == 0) {
                this.loadActiveList(this.searchId.id); //刷新列表
                this.ruleForm = a;
                this.showRadio = true;
                this.showTransfer = true;
                this.helpStudentData.map((item) => {
                  item.disabled = false;
                });
                this.helpTypeOptionsForm = [
                  {
                    helpTypeOptions: [],
                    key: 1,
                    helpType: null,
                    behelpTypeOptions: [],
                    behelpType: null,
                  },
                ];
                this.$message({
                  message: "创建活动成功",
                  type: "success",
                });
                this.centerDialogVisible = false;
                let userId = Number(this.utils.userId());
                _hmt.push([
                  "_trackEvent",
                  "成功创建活动",
                  "创建活动",
                  "活动:" + this.ruleForm.activityTitle,
                  userId,
                ]);
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$message({
              message: "请完善信息！",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 新建活动选中群组
    choiceRadio (val) {
      if (val == 2) {
        this.data = this.groupData;
        this.ruleForm.transferValue = [];
      }
      if (val == 1) {
        this.data = this.studentData;
        this.ruleForm.transferValue = [];
      }
    },
    // 选择班级渲染群组
    choiceSelect (val) {
      groupList({ classId: val, pageNum: null, pageSize: 5 }).then((res) => {
        res.data.list.map((item) => {
          item["label"] = item.name + "(" + item.list.length + "人)";
          item["key"] = item.id;
        });
        this.groupData = res.data.list;
      });
    },
    // 关闭二次确认
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.showOpan = false;
          done();
          this.cancel();
          this.helpStudentData.map((item) => {
            item.disabled = false;
          });
        })
        .catch((_) => { });
    },
    // 取消创建活动重置状态
    cancel () {
      // this.showlabel = true; //显示互动
      this.Thelabeltemplate = [];
      this.centerDialogVisible = false;
      let a = {
        activityTitle: null,
        cascader: null,
        activityValue: null,
        startDate: null,
        endDate: "",
        showSwitch: true,
        checked: false,
        radio: "0",
        textDescribe: null,
        textGuidance: null,
        transferValue: [],
        radioParticipate: "1",
        urgent: false,
        pitchonLabel: null, //选中的标签 pitchonLabel
      };
      this.ruleForm = a;
      this.state[0] = "启用";
      this.state[1] = "停止";
      this.showTransfer = true;
      this.showRadio = true;
      this.showOpan = false;
      this.helpTypeOptionsForm = [
        {
          helpTypeOptions: [],
          key: 1,
          helpType: null,
          behelpTypeOptions: [],
          behelpType: null,
        },
      ];
      this.helpStudentData.map((item) => {
        item.disabled = false;
      });
    },
    preview (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let caseIds = "";
          if (this.ruleForm.activityValue == 0) {
            caseIds = this.cascadeData;
          } else {
            caseIds = this.ruleForm.cascader[1];
          }
          this.yuLanObj = {
            caseIds: caseIds,
          };
          if (this.ruleForm.activityValue == 2) {
            var arr = [];
            //是标签
            this.ruleForm.tagder.map((item, index) => {
              arr.push(item[1]);
            });
            this.yuLanObj = {
              caseIds: arr,
              labelId: this.ruleForm.pitchonLabel,
            };
          }
          this.showPreview = true;
        } else {
          return false;
        }
      });
    },
    unique (arr) {
      const res = new Map();
      return arr.filter(
        (arr) => !res.has(arr.caseId) && res.set(arr.caseId, 1)
      );
    },
  },
  // directives: {
  //   'el-select-loadmore': (el, binding) => {
  //     // 获取element-ui定义好的scroll盒子

  //     const SELECTWRAP_DOM = document.getElementsByClassName(".el-cascader-menu__list");
  //     console.log(SELECTWRAP_DOM)
  //     if (SELECTWRAP_DOM) {
  //       console.log(1)
  //       SELECTWRAP_DOM.addEventListener("scroll", function () {
  //         /**
  //          * scrollHeight 获取元素内容高度(只读)
  //          * scrollTop 获取或者设置元素的偏移值,
  //          *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
  //          * clientHeight 读取元素的可见高度(只读)
  //          * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
  //          * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
  //          */
  //         const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
  //         if (condition) binding.value();
  //       });
  //     }
  //   }
  // }
};
</script>
<style lang='less' scope>
@import './style/index.less';
@import '../../style/mouse.less';
</style>
