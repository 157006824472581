<!-- 教学活动树 -->
<template>
  <div class="scroll-right">
    <el-scrollbar style="height:100%">
      <span class="tree-text">专题变式</span>
      <el-row :gutter="4">
        <el-col :span="10">
          <div class="grid-content bg-purple">教学资源</div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">状态</div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">操作</div>
        </el-col>
      </el-row>
      <el-tree
        :default-expand-all="false"
        :data="data"
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span :class="node.level == 3?'showText':node.level == 2?'showText1':''">{{ node.label }}</span>
          <span>
            <span v-if="node.level == 3" class="tree-completion">{{node.data.msg}}</span>
            <el-button
              v-if="node.level == 3"
              type="text"
              size="mini"
              @click="() => getInto(node, data)"
            >进入</el-button>
          </span>
        </span>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
import { activeGetSpecialtopic } from '../../api/api'
export default {
  props: { activityList: { type: Object, default: () => { } } },
  data () {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeDataK12: null,//树状图K12数据
      treeDataIMMEX: null,//树状图immex数据
      grade: null,//年级
      subject: null,//学科
      status: 0,//0  K12  1  IMMEX
    }
  },
  components: {},
  watch: {
    // 监听传进来的 activityList
    activityList (val, old) {
      if (val !== old) {
        this.grade = val.grade
        this.subject = val.subjects
        this.load()
      }
    }
  },
  mounted () {
    this.analysis()
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity('新建K12活动') || this.utils.identity('K12做题')) {
        this.status = 0
      }
      if (this.utils.identity('新建IMMEX活动') || this.utils.identity('IMX做题')) {
        this.status = 1
      }
    },
    handleNodeClick () { },
    //   判断弹框
    getInto (node) {
      this.$router.push({ path: '/activityDetail', query: { id: node.data.activeId, classId: this.$route.query.classId, stemType: node.data.stemType } })
    },
    // 加载数据
    load () {
      let classId = this.$route.query.classId
      //  获取所有专题和变式
      if (this.status == 1) {
        // 请求五年级IMMEX数据
        activeGetSpecialtopic({
          "grade": this.grade,
          "subject": this.subject,
          'specialtopicType': 1,
        }).then(res => {
          if (res.code == 0) {
            res.data.map(item => {
              item['label'] = item.chapterTitle
              item['children'] = item.npCases
              if (item.children) {
                item.children.map((itm, index) => {
                  itm['label'] = item.chapterTitle + itm.caseSN
                  itm['children'] = itm.allTask
                  itm.children.map(it => {
                    it['label'] = it.activeTitle
                    if (it.isComplete == 1) {
                      it['msg'] = '已完成'
                    } else {
                      it['msg'] = '未完成'
                    }
                  })
                })
              }
            })
            this.treeDataIMMEX = res.data
            this.data = this.treeDataIMMEX
          }

        })
      } else if (this.status == 0) {
        activeGetSpecialtopic({
          "grade": this.grade,
          "subject": this.subject,
          'specialtopicType': 0,
          "id": Number(classId)
        }).then(res => {
          if (res.data) {
            res.data.map(item => {
              item['label'] = item.chapterTitle
              item['children'] = item.npCases
              item.children.map(itm => {
                itm['label'] = itm.caseSN
                itm['children'] = itm.allTask
                if (itm.children) {
                  itm.children.map(it => {
                    it['label'] = it.activeTitle
                    if (it.isComplete == 1) {
                      it['msg'] = '已完成'
                    } else {
                      it['msg'] = '未完成'
                    }
                  })
                }

              })
            })
            this.treeDataK12 = res.data
            this.data = this.treeDataK12
          }
        })
      }
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/activitieTree/index.less';
@import '../../style/mouse.less';
.el-scrollbar/deep/.el-scrollbar__wrap {
  position: relative;
  overflow-x: hidden;
}
.el-select {
  position: absolute;
  top: 0;
  right: 0;
}
.showText {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
}
.showText1 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
}
</style>
